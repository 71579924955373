

























































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import AddRxMedHeader from '@/components/medications/addRx/MedHeader.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import Loading from '@/components/Loading.vue'
import Icon from '@/components/Icon.vue'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import SignupPhysician from '@/models/SignupPhysician'
import Physician from '@/models/Physician'
import Pharmacy from '@/models/Pharmacy'
import { BannerInfo } from '@/vuex/banners'
import { DeliveryOption } from '@/util/addRx'

@Component({
  components: {
    AddRxMedHeader,
    LinkArrowIcon,
    Loading,
    Icon,
  },
  computed: {
    ...mapState('addRx', ['delivery', 'prescriber', 'prescriberDetails']),
    ...mapGetters('addRx', ['medStrength', 'pharmacy']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class Review extends Vue {
  isInProgress!: (key: string, id?: string) => boolean
  medStrength!: PrescriptionStrength
  prescriber!: Physician
  prescriberDetails!: SignupPhysician
  pharmacy?: Pharmacy
  delivery!: DeliveryOption

  async submit() {
    await this.$store.dispatch('addRx/createMedRequest', {
      medStrength: this.medStrength,
      prescriber: {
        ...this.prescriberDetails,
        ...this.prescriber,
      },
      pharmacy: this.pharmacy,
      delivery: this.delivery,
    })
    this.$router.push({
      name: 'medications',
    })
    this.showBanner()
  }

  showBanner() {
    const message = this.pharmacy
      ? this.$t('addRx.successMessageTransfer')
      : this.$t('addRx.successMessageNew')
    const banner: BannerInfo = {
      id: 'add-rx-success',
      bgVariant: 'primary',
      title: this.$t('Request submitted'),
      message: `${message} ${this.$t('addRx.successMessageCTA')}`,
    }
    this.$store.dispatch('showBanner', banner)
  }

  get deliveryOption() {
    if (!this.delivery) return ''

    switch (this.delivery) {
      case DeliveryOption.NEXT:
        return this.$t('Add to next shipment')
      case DeliveryOption.ASAP:
        return this.$t('As soon as possible')
      default:
        throw new UnreachableCaseError(this.delivery)
    }
  }

  get submitMessage() {
    if (this.pharmacy) {
      return this.$t('addRx.submitMessage.pharmacy')
    }

    return this.$t('addRx.submitMessage.noPharmacy')
  }
}
